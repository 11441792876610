import * as Sentry from '@sentry/browser';
import { setLanguage } from "redux-i18n"
import moment from 'moment'
import randomString from 'random-string'

import { ProjectCostsConsts } from "../reducers/ProjectCostsReducer"
import { CompanyConst } from "../reducers/CompanyReducer"
import { ReportsConst } from "../reducers/ReportsReducer"
import { EventConst } from "../reducers/EventReducer"
import { FileConst } from "../reducers/FileReducer"
import { MessageConst } from "../reducers/MessagesReducer"
import { TransferConst } from "../reducers/TransfersReducer";
import { UserConst } from "../reducers/UserReducer"
import { QuickActionsConsts } from "../reducers/QuickActionsReducer"

import { AlertActions } from './AlertActions'

import { history } from "../helpers/history"
import routes from "../helpers/routes"
import myFetch from "../helpers/fetch"
import availableQuickActions from '../helpers/availableQuickActions'



//wersja w postaci numerRoku.tydzieńRoku.numerAktualizacji
//numer wersji zmieniany co rok -> czyli gdy ostatni tydzień roku to 52 (v 1.52.10) to następna ma pierwszy tydzień (v 2.1.1)
//ten sam numer musi być w API w pliku server/version.json
const APP_VERSION = "5.47.2"

export const UserActions = {
    changeLanguage,
    toggleNewEasyDocsVersion,
    changeOcrModel,
    checkIfLogged,
    registerForAppointment,
    newRegister,
    activationLinkClick,
    newAccountActivation,
    newAccountAndCompanyActivation,
    register,
    sendPasswordResetEmail,
    resetPassword,
    confirmEmail,
    login,
    hideFirstLoginPopup,
    hideLoginEasyGuardPopup,
    logout,
    updateUserName,
    changePassword,
    changeProfilePic,
    deleteProfilePic,
    updateUserBankAccount,
    addNewUserBankAccount,
    deleteUserBankAccount,
    finishAdminOnboarding,
    hideSalesKsefInfo,
    withdrawConsent,
    getAllUsers,
    addCompany,
    createProject,
    deleteProject,
    renameProject,
    handleDeleteProjectSocketAcction,
    getAllProjects,
    getUserProjects,
    toggleFavouriteForProject,
    switchDashboardViewModel,
    setCurrentProject,
    toggleNotifyAboutEvents,
    toggleNotifyAboutDocs,
    toggleNotifyAboutPayments,
    toggleNotifyAboutUrgentDocs,
    changeMontOnDashboardCalendar,
    toogleDayForTooltipCalendar,
    submitTicket,
    switchFileManagerViewMode,
    sortAllUsersForSuperadmin,
    getEmailsThatSentFiles,
    getFileCountsForProject,
    toggleOverlay,
    setWhatsNewVersion,
    sendEmailsAsSuperadmin,
    getAllInformationEmails,
    sortAllInformationEmails,
    getAllEasyDocsPayments,
    getTransactionDetails,
    getEasyDocsPaymentsReport,
    getMarketingReport,
    sortAllEDPaymentsForSuperadmin,
    downloadEDPaymentInvoice,
    getWhatsNewList,
    createWhatsNew,

    turnOn2FA,
    turnOff2FA,
    sendEasyGuardDeviceResetEmail,
    resetEasyGuardDevice,
    logoutAllDevices,
    logoutAllDevicesSocketHandler,
    registerEasyGuardDevice,
    enable2FASocketHandler,
    disable2FASocketHandler,

    toggleQuickAction,
    saveCustomTableColumns,
    getDocsRequiringMyAttention,
    collapseDocsRequiringAttentionsProjectGroup,
    setSortFieldForDocsRequiringAttention,
    toggleNotificationSettingForProject,

    sendErrorEmail
}

function changeLanguage(lang) {
    moment.locale(lang);
    return dispatch => {
        localStorage.setItem("language", lang)
        dispatch(setLanguage(lang))


        if (localStorage.getItem('token') && localStorage.getItem('user')) {
            myFetch(`/users/set-language`, {
                method: 'put', body: {
                    lang: localStorage.getItem('language')
                }
            }).then(res => {
                dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: res.user })
            }).catch(err => {
                console.log(err)
            })
        }
    }
}

function toggleNewEasyDocsVersion(newEasyDocsVersion) {
    return dispatch => {
        dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { new_easydocs_version: newEasyDocsVersion } })
        myFetch(`/users/toggle-new-easydocs-version`, {
            method: 'put', body: {
                useNewVersion: newEasyDocsVersion
            }
        }).then(res => {
            if (!res.success) {
                dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { new_easydocs_version: !newEasyDocsVersion } })
            }
        }).catch(err => {
            dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { new_easydocs_version: !newEasyDocsVersion } })
            handleError(err, dispatch, `/users/toggle-new-easydocs-version`)
        })
    }
}

function changeOcrModel(currentModel, newOcrModel) {
    return dispatch => {
        dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { ocr_model: newOcrModel } })
        myFetch(`/users/change-ocr-model`, {
            method: 'put', body: {
                ocrModel: newOcrModel
            }
        }).then(res => {
            if (!res.success) {
                dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { ocr_model: currentModel } })
            }
        }).catch(err => {
            dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { ocr_model: currentModel } })
            handleError(err, dispatch, `/users/toggle-new-easydocs-version`)
        })
    }
}

function checkIfLogged() {
    return dispatch => {
        if (localStorage.getItem('version') === null || localStorage.getItem('version') !== APP_VERSION) {
            localStorage.setItem('version', APP_VERSION)
        }

        if (localStorage.getItem('token') && localStorage.getItem('user')) {
            dispatch(AlertActions.info("Autologowanie...", 1000))
            console.log(JSON.parse(localStorage.getItem('user')))
            var user = JSON.parse(localStorage.getItem('user'))
            myFetch(`/users/get-my-profile`, { method: 'get' }).then(res => {
                if (res.success) {
                    Sentry.configureScope(function (scope) {
                        scope.setUser({
                            id: res.profile.id,
                            email: res.profile.email
                        });
                    });
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'login',
                        'userId': res.profile.id,
                        'email': res.profile.email,
                        'language': res.profile.language
                    })

                    if (!res.profile.finished_admin_onboarding && localStorage.getItem('adminOnboarding') === 'HIDDEN') localStorage.setItem('adminOnboarding', 'MINI')

                    dispatch({ type: UserConst.LOGGED_TRUE, user: res.profile, firstLogin: res.firstLogin })
                    myFetch(`/users/set-last-logged-in`, { method: 'put' })
                    if (res.profile.language) {
                        localStorage.setItem("language", res.profile.language)
                        dispatch(setLanguage(res.profile.language))
                    } else if (!res.profile.language && localStorage.getItem('language')) {
                        myFetch(`/users/set-language`, {
                            method: 'put', body: {
                                lang: localStorage.getItem('language')
                            }
                        }).then(res => {
                            dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: res.user })
                        }).catch(err => {
                            console.log(err)
                        })
                    }

                    if (localStorage.getItem('menuExtended') === null) {
                        localStorage.setItem('menuExtended', true)
                    }
                }
            })
        } else {
            dispatch({ type: UserConst.LOGGED_FALSE })
        }

        if (localStorage.getItem('overlayPreferance')) {
            dispatch({ type: UserConst.SET_OVERLAY_PREFERANCE, preferances: JSON.parse(localStorage.getItem('overlayPreferance')) })
        } else {
            var overlayPreferance = {
                ocrScreen: false,
                dashboard: false,
                reports: false,
                docs: false,
                calendar: false,
                settings: false,
                adminProjectsMain: false,
                adminProjectsProject: false,
                adminProjectsMembers: false,
                adminCompany: false,
                adminCounterparties: false
            }

            dispatch({ type: UserConst.SET_OVERLAY_PREFERANCE, preferances: overlayPreferance })
            localStorage.setItem('overlayPreferance', JSON.stringify(overlayPreferance))
        }
    }
}

function registerForAppointment(email, phone, date, time, name, cb) {
    return dispatch => {
        myFetch(`/users/register-for-appointment`, {
            method: 'post', body: {
                email: email.toLowerCase(),
                phone: phone,
                date: date,
                time: time,
                name: name,
                ref: window.location.href
            }
        }).then(res => {
            cb(true)
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'bookingFormSubmit',
                'formName': 'formBooking' // Nazwa formularza
            });
        }).catch(err => {
            cb(false)
            handleError(err, dispatch, `/users/register-for-appointment`)
        })
    }
}

function newRegister(email, campaign, ref, subType, captcha, gender, cb) {
    return dispatch => {
        myFetch(`/users/new-register`, {
            method: 'post', body: {
                email: email.toLowerCase(),
                campaign: campaign,
                ref: ref,
                subType: subType,
                captcha: captcha,
                gender: gender,
            }
        }).then(res => {
            if (res.success) {
                cb(true)
                var totalCost = subType === 'BASIC' ? 199 : subType === 'PRO' ? 299 : subType === 'PRO+' ? 599 : subType === 'FREE' ? 0 : 49
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'sign_up',
                    ecommerce: {
                        customerBillingEmail: email,
                        customerBillingFirstName: '',
                        customerBillingLastName: '',
                        currency: 'PLN',
                        value: parseFloat(totalCost),
                        tax: parseFloat(totalCost) * 0.23,
                        transaction_id: '',
                        items: [
                            {
                                item_id: subType === 'BASIC' ? "Varian_2" : subType === 'PRO' ? 'Varian_3' : subType === 'PRO+' ? 'Varian_4' : subType === 'FREE' ? 'Varian_1' : 'Varian_5',
                                item_name: subType,
                                coupon: "Poleć Znajomemu",
                                currency: "PLN",
                                discount: 0,
                                price: parseFloat(totalCost),
                                quantity: 1
                            }
                        ]
                    }
                });
            } else {
                cb(false)
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            cb(false)
            handleError(err, dispatch, `/users/new-registration`)
        })
    }
}

function activationLinkClick(email, type) {
    return dispatch => {
        myFetch(`/users/activation-link-click`, {
            method: 'post', body: {
                email: email.toLowerCase(),
                type: type
            }
        }).then(res => {
        }).catch(err => {
            //handleError(err, dispatch, `/users/new-activate-account`)
        })
    }
}

function newAccountActivation(email, token, firstName, lastName, password, cb) {
    return dispatch => {
        myFetch(`/users/new-activate-account`, {
            method: 'put', body: {
                email: email.toLowerCase(),
                token: token,
                firstName: firstName,
                lastName: lastName,
                password: password,
                companyRegister: false
            }
        }).then(res => {
            if (res.success || res.token) {
                dispatch(AlertActions.info("Your account has been activated. You will be logged in automatically"))
                localStorage.setItem('token', res.token)

                var overlayPreferance = {
                    ocrScreen: false,
                    dashboard: false,
                    reports: false,
                    docs: false,
                    calendar: false,
                    settings: false,
                    adminProjectsMain: false,
                    adminProjectsProject: false,
                    adminProjectsMembers: false,
                    adminCompany: false,
                    adminCounterparties: false
                }

                dispatch({ type: UserConst.SET_OVERLAY_PREFERANCE, preferances: overlayPreferance })
                localStorage.setItem('overlayPreferance', JSON.stringify(overlayPreferance))
                Sentry.configureScope(function (scope) {
                    scope.setUser({
                        id: res.profile.id,
                        email: res.profile.email
                    });
                });
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'login',
                    'userId': res.profile.id,
                    'email': res.profile.email,
                    'language': res.profile.language
                })

                dispatch({ type: UserConst.LOGGED_TRUE, user: res.profile, firstLogin: true })
                history.push(routes.panel.dashboard)
                localStorage.setItem('user', JSON.stringify(res.profile))
                localStorage.setItem('menuExtended', true)
                myFetch(`/users/set-last-logged-in`, { method: 'put' })
                if (res.profile.language) {
                    localStorage.setItem("language", res.profile.language)
                    dispatch(setLanguage(res.profile.language))
                } else if (!res.profile.language && localStorage.getItem('language')) {
                    myFetch(`/users/set-language`, {
                        method: 'put', body: {
                            lang: localStorage.getItem('language')
                        }
                    }).then(res => {
                        dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: res.user })
                    }).catch(err => {
                        console.log(err)
                    })
                }
            } else {
                cb(false)
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            cb(false)
            handleError(err, dispatch, `/users/new-activate-account`)
        })
    }
}

function newAccountAndCompanyActivation(email, token, vatId, subType, campaign, ref, companyName, firstName, lastName, password, cb) {
    return dispatch => {
        myFetch(`/users/new-activate-account-and-company`, {
            method: 'put', body: {
                email: email.toLowerCase(),
                token: token,
                vatId: vatId,
                subType: subType,
                campaign: campaign,
                ref: ref,
                companyName: companyName,
                firstName: firstName,
                lastName: lastName,
                password: password,
            }
        }).then(res => {
            if (res.success || res.token) {
                dispatch(AlertActions.info("Your account has been activated. You will be logged in automatically"))

                var totalCost = subType === 'BASIC' ? 199 : subType === 'PRO' ? 299 : subType === 'PRO+' ? 599 : subType === 'FREE' ? 0 : 49
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'pozytywnaRejestracja',
                    ecommerce: {
                        customerBillingEmail: email,
                        customerBillingFirstName: firstName,
                        customerBillingLastName: lastName,
                        currency: 'PLN',
                        value: parseFloat(totalCost),
                        tax: parseFloat(totalCost) * 0.23,
                        transaction_id: '',
                        items: [{
                            item_id: subType === 'BASIC' ? "Varian_2" : subType === 'PRO' ? 'Varian_3' : subType === 'PRO+' ? 'Varian_4' : subType === 'FREE' ? 'Varian_1' : 'Varian_5',
                            item_name: subType,
                            coupon: "Poleć Znajomemu",
                            currency: "PLN",
                            discount: 0,
                            price: parseFloat(totalCost),
                            quantity: 1
                        }]
                    }
                });

                localStorage.setItem('token', res.token)

                var overlayPreferance = {
                    ocrScreen: false,
                    dashboard: false,
                    reports: false,
                    docs: false,
                    calendar: false,
                    settings: false,
                    adminProjectsMain: false,
                    adminProjectsProject: false,
                    adminProjectsMembers: false,
                    adminCompany: false,
                    adminCounterparties: false
                }

                dispatch({ type: UserConst.SET_OVERLAY_PREFERANCE, preferances: overlayPreferance })
                localStorage.setItem('overlayPreferance', JSON.stringify(overlayPreferance))
                Sentry.configureScope(function (scope) {
                    scope.setUser({
                        id: res.profile.id,
                        email: res.profile.email
                    });
                });
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'login',
                    'userId': res.profile.id,
                    'email': res.profile.email,
                    'language': res.profile.language
                })

                dispatch({ type: UserConst.LOGGED_TRUE, user: res.profile, firstLogin: true })
                history.push(routes.panel.dashboard)
                localStorage.setItem('user', JSON.stringify(res.profile))
                localStorage.setItem('menuExtended', true)
                myFetch(`/users/set-last-logged-in`, { method: 'put' })
                if (res.profile.language) {
                    localStorage.setItem("language", res.profile.language)
                    dispatch(setLanguage(res.profile.language))
                } else if (!res.profile.language && localStorage.getItem('language')) {
                    myFetch(`/users/set-language`, {
                        method: 'put', body: {
                            lang: localStorage.getItem('language')
                        }
                    }).then(res => {
                        dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: res.user })
                    }).catch(err => {
                        console.log(err)
                    })
                }
            } else {
                cb(false)
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            cb(false)
            handleError(err, dispatch, `/users/new-activate-account-and-company`)
        })
    }
}

function register(newUser, token, cb) {
    return dispatch => {
        myFetch(`/users/activate-account`, {
            method: 'put', body: {
                data: {
                    email: newUser.email.toLowerCase(),
                    name: newUser.firstName,
                    surname: newUser.lastName,
                    password: newUser.password,
                },
                token: token
            }
        }).then(res => {
            if (res.success) {
                cb(true)
                history.push(routes.login)
                dispatch(AlertActions.info("Your account has been activated. You will be logged in automatically"))
                var subType = res.subType
                var totalCost = subType === 'BASIC' ? 199 : subType === 'PRO' ? 299 : subType === 'PRO+' ? 599 : subType === 'FREE' ? 0 : 49
                window.lintrk('track', { conversion_id: 5592842 });
            } else {
                cb(false, res.msg)
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            cb(false)
            if (err && err.message && err.message.includes("`email` Email already exists")) {
                dispatch(AlertActions.danger("User with that e-mail address already exists."))
            } else {
                dispatch(AlertActions.setError(err));
                dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."))
            }
        })
    }
}

function sendPasswordResetEmail(email, cb) {
    return dispatch => {
        myFetch(`/users/send-password-reset-email`, {
            method: 'post', body: {
                email: email.toLowerCase(),
                lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
            }
        }).then(res => {
            cb()
            dispatch(AlertActions.info("Password reset request send. An email with further instructions will be sent to the address you provided", 12000))
        }).catch(err => {
            cb()
            handleError(err, dispatch, `/users/send-password-reset-email`)
        })
    }
}

function resetPassword(email, token, password) {
    return dispatch => {
        dispatch({ type: UserConst.SEND_SET_NEW_PASSWORD_REQUEST })
        myFetch(`/users/reset-password`, {
            method: 'put', body: {
                email: email.toLowerCase(),
                token: token,
                password: password
            }
        }).then(res => {
            dispatch({ type: UserConst.GOT_SET_NEW_PASSWORD_RESPONSE })
            if (res.success) {
                dispatch(AlertActions.info("Password has beed changed"))
                history.push(routes.login)

            } else {
                dispatch(AlertActions.info(res.msg))
            }
        }).catch(err => {
            dispatch({ type: UserConst.GOT_SET_NEW_PASSWORD_RESPONSE })
            handleError(err, dispatch, `/users/reset-password`)
        })
    }
}

function confirmEmail(email, token, type) {
    return dispatch => {
        myFetch(`/users/confirm-email`, {
            method: 'post', body: {
                email: email.toLowerCase(),
                token: token,
                type: type
            }
        }).then(res => {
            if (res.success) {
                dispatch(AlertActions.info("Email confirmed. You can log in now."))
            } else {
                dispatch(AlertActions.setError(res));
                dispatch(AlertActions.danger(res.message))
            }
        }).catch(err => {
            handleError(err, dispatch, `/users/confirm-email`)
        })
    }

}

function login(email, password, token, captcha, cb) {
    return dispatch => {
        if (localStorage.getItem('version') === null || localStorage.getItem('version') !== APP_VERSION) {
            localStorage.setItem('version', APP_VERSION)
        }

        myFetch(`/users/login`, {
            method: 'post', body: {
                email: email.toLowerCase(),
                password: password,
                token: token,
                time: moment().unix(),
                captcha: captcha
            }
        }).then(res => {
            cb()
            console.log(res)
            localStorage.setItem('token', res.id)

            if (localStorage.getItem('overlayPreferance')) {
                dispatch({ type: UserConst.SET_OVERLAY_PREFERANCE, preferances: JSON.parse(localStorage.getItem('overlayPreferance')) })
            } else {
                var overlayPreferance = {
                    ocrScreen: false,
                    dashboard: false,
                    reports: false,
                    docs: false,
                    calendar: false,
                    settings: false,
                    adminProjectsMain: false,
                    adminProjectsProject: false,
                    adminProjectsMembers: false,
                    adminCompany: false,
                    adminCounterparties: false
                }

                dispatch({ type: UserConst.SET_OVERLAY_PREFERANCE, preferances: overlayPreferance })
                localStorage.setItem('overlayPreferance', JSON.stringify(overlayPreferance))
            }

            myFetch(`/users/get-my-profile`, { methot: 'get' }).then(res => {
                console.log(res)
                if (res.success) {
                    Sentry.configureScope(function (scope) {
                        scope.setUser({
                            id: res.profile.id,
                            email: res.profile.email
                        });
                    });
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'login',
                        'userId': res.profile.id,
                        'email': res.profile.email,
                        'language': res.profile.language
                    })

                    if (!res.profile.finished_admin_onboarding && localStorage.getItem('adminOnboarding') === 'HIDDEN') localStorage.setItem('adminOnboarding', 'MINI')
                    dispatch({ type: UserConst.LOGGED_TRUE, user: res.profile, firstLogin: res.firstLogin })
                    localStorage.setItem('user', JSON.stringify(res.profile))
                    myFetch(`/users/set-last-logged-in`, { method: 'put' })
                    if (res.profile.language) {
                        localStorage.setItem("language", res.profile.language)
                        dispatch(setLanguage(res.profile.language))
                    } else if (!res.profile.language && localStorage.getItem('language')) {
                        myFetch(`/users/set-language`, {
                            method: 'put', body: {
                                lang: localStorage.getItem('language')
                            }
                        }).then(res => {
                            dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: res.user })
                        }).catch(err => {
                            console.log(err)
                        })
                    }

                    if (localStorage.getItem('menuExtended') === null) {
                        localStorage.setItem('menuExtended', true)
                    }
                }
            })
        }).catch(err => {
            cb()
            if (err && err.message && err.message === "Ask for EasyGuard") {
                dispatch({ type: UserConst.ASK_FOR_EASYGUARD })
            } else if (err && err.message && err.message === "You need to verify your email first!") {
                dispatch(AlertActions.warning("You need to verify your email first!"))
            } else if (err && err.message && err.message === "You cant\'t login when you don\'t agree to terms of service") {
                dispatch(AlertActions.warning(err.message))
            } else if (err && err.message && err.message === "Invalid EasyGuard token") {
                dispatch(AlertActions.warning(err.message))
            } else {
                if (err.networkError) {
                    return;
                }
                dispatch(AlertActions.warning("Invalid credentials"))
            }
        })
    }
}

function hideFirstLoginPopup() {
    return dispatch => {
        dispatch({ type: UserConst.HIDE_FIRST_LOGIN_POPUP })
    }
}

function hideLoginEasyGuardPopup() {
    return dispatch => {
        dispatch({ type: UserConst.HIDE_ASK_FOR_EASYGUARD })
    }
}

function logout(email) {
    return dispatch => {
        myFetch(`/users/logout`, {
            method: 'post', body: {
                email: email ? email.toLowerCase() : email
            }
        }).then(res => {
            console.log(res)
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            dispatch({ type: UserConst.LOGGED_FALSE })
            dispatch({ type: UserConst.LOGOUT })
            dispatch({ type: EventConst.LOGOUT })
            dispatch({ type: CompanyConst.LOGOUT })
            dispatch({ type: ReportsConst.LOGOUT })
            dispatch({ type: FileConst.LOGOUT })
            dispatch(AlertActions.info("You have been logged out.", 5000))
        }).catch(err => {
            handleError(err, dispatch, `/users/logout`)
        })
    }
}

function updateUserName(firstName, lastName) {
    return dispatch => {
        dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { first_name: firstName, last_name: lastName } })
        myFetch(`/users/change-name`, {
            method: 'put', body: {
                firstName: firstName,
                lastName: lastName
            }
        }).then(res => {
        }).catch(err => {
            handleError(err, dispatch, `/users/change-password`)
        })
    }
}

function changePassword(oldPass, newPass, cb) {
    return dispatch => {
        myFetch(`/users/change-password`, {
            method: 'post', body: {
                oldPassword: oldPass,
                newPassword: newPass
            }
        }).then(res => {
            if (cb) cb(true)
        }).catch(err => {
            if (cb) cb(false)
            if (err.code === 'INVALID_PASSWORD') {
                dispatch(AlertActions.warning("The current password is incorrect"))
            } else {
                handleError(err, dispatch, `/users/change-password`)
            }
        })
    }
}

function changeProfilePic(pic, cb = () => { }) {
    return dispatch => {
        myFetch(`/users/change-profile-pic`, {
            method: 'put', body: {
                profilePic: pic,
            }
        }).then(res => {
            cb(res.success)
            if (res.success) {
                dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: res.user })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            cb(false)
            handleError(err, dispatch, `/users/change-profile-pic`)
        })
    }
}

function deleteProfilePic() {
    return dispatch => {
        myFetch(`/users/delete-profile-pic`, { method: 'put' }).then(res => {
            if (res.success) {
                dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: res.user })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            handleError(err, dispatch, `/users/delete-profile-pic`)
        })
    }
}

function saveAccountNumber(numberPln, numberEur) {
    return dispatch => {
        myFetch(`/users/account-number`, {
            method: 'put', body: {
                numberPln: numberPln,
                numberEur: numberEur
            }
        }).then(res => {
            if (res.success) {
                dispatch(AlertActions.info("Account number updated"))
                dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: res.user })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            handleError(err, dispatch, `/users/account-number`)
        })
    }
}

function updateUserBankAccount(bankAccount) {
    return dispatch => {
        myFetch(`/user-bank-accounts/update`, {
            method: 'put', body: {
                bankAccount: bankAccount
            }
        }).then(res => {
            if (res.success) {
                dispatch(AlertActions.info("Bank account updated"))
                dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { banks: res.banks } })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            handleError(err, dispatch, `/user-bank-accounts/update`)
        })
    }
}

function addNewUserBankAccount(bankAccount, cb) {
    return dispatch => {
        myFetch(`/user-bank-accounts/add`, {
            method: 'post', body: {
                bankAccount: bankAccount
            }
        }).then(res => {
            cb()
            if (res.success) {
                dispatch(AlertActions.info("Bank account created"))
                dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { banks: res.banks } })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            cb()
            handleError(err, dispatch, `/user-bank-accounts/add`)
        })
    }
}

function deleteUserBankAccount(bankId) {
    return dispatch => {
        myFetch(`/user-bank-accounts/delete`, {
            method: 'delete', body: {
                bankId: bankId
            }
        }).then(res => {
            if (res.success) {
                dispatch(AlertActions.info("Bank account deleted"))
                dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { banks: res.banks } })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            handleError(err, dispatch, `/user-bank-accounts/delete`)
        })
    }
}

function finishAdminOnboarding(companyId) {
    return dispatch => {
        dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { finished_admin_onboarding: true } })
        myFetch(`/users/finish-admin-onboarding`, {
            method: 'put', body: {
                companyId: companyId
            }
        })
            .then(res => {
                if (res.success) {
                    localStorage.removeItem('adminOnboarding')
                } else {
                    dispatch(AlertActions.warning(res.msg))
                    dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { finished_admin_onboarding: false } })
                }
            }).catch(err => {
                dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { finished_admin_onboarding: false } })
                handleError(err, dispatch, `/users/finish-admin-onboarding`)
            })
    }
}

function hideSalesKsefInfo() {
    return dispatch => {
        dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { seen_ksef_sales_popup: true } })
        myFetch(`/users/hide-sales-ksef-info`, {
            method: 'put', body: {}
        })
            .then(res => {
                if (!res.success) {
                    dispatch(AlertActions.warning(res.msg))
                    dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { seen_ksef_sales_popup: false } })
                }
            }).catch(err => {
                dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { seen_ksef_sales_popup: false } })
                handleError(err, dispatch, `/users/hide-sales-ksef-info`)
            })
    }
}

function withdrawConsent(email) {
    return dispatch => {
        myFetch(`/users/withdraw-consent`, { method: 'put' }).then(res => {
            if (res.success) {
                myFetch(`/users/logout`, {
                    method: 'post', body: {
                        email: email.toLowerCase()
                    }
                }).then(res => {
                    console.log(res)
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    dispatch({ type: UserConst.LOGGED_FALSE })
                    dispatch({ type: UserConst.LOGOUT })
                    dispatch({ type: EventConst.LOGOUT })
                    dispatch({ type: CompanyConst.LOGOUT })
                    dispatch({ type: ReportsConst.LOGOUT })
                    dispatch({ type: FileConst.LOGOUT })
                    dispatch(AlertActions.info("You have been logged out.", 5000))
                }).catch(err => {
                    handleError(err, dispatch, `/users/logout`)
                })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            handleError(err, dispatch, `/users/withdraw-consent`)
        })
    }
}


function getAllUsers() {
    return dispatch => {
        myFetch(`/users/superadmin`, { method: 'get' }).then(res => {
            if (res.success) {
                dispatch({ type: UserConst.GET_ALL_USERS, users: res.users })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            handleError(err, dispatch, `/users/superadmin`)
        })
    }
}


function createProject(data, companyId, usersToAsing, usersToInviteAndAsign, creatorId) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/projects/create-project`, {
            method: 'post', body: {
                companyId: companyId,
                name: data.name,
                code: data.code,
            }
        }).then(projectRes => {
            myFetch(`/user-projects/change-users-role`, {
                method: 'put', body: {
                    projectId: projectRes.project.id,
                    users: usersToAsing,
                    role: 1,
                }
            }).then(res => {
                myFetch(`/user-projects/change-users-role`, {
                    method: 'put', body: {
                        projectId: projectRes.project.id,
                        users: [creatorId],
                        role: 3,
                    }
                }).then(res => {
                    myFetch(`/user-projects/my-projects`, { method: 'get' }).then(result => {
                        dispatch({ type: UserConst.GET_USER_PROJECTS, userProjects: result.userProjects, groupedProjects: result.groupedProjects })
                        history.push(routes.panel.settings.projects)
                        dispatch(AlertActions.info("New binder was created"))
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    }).catch(err => {
                    })
                    myFetch(`/projects/get-company-projects/${companyId}`, { method: "get" }).then((res) => {
                        dispatch({
                            type: CompanyConst.GET_COMPANY_PROJECTS,
                            companyProjects: res.projects,
                        });
                    }).catch((err) => {
                    });
                }).catch((err) => {
                });
            }).catch(err => {
            })

            if (projectRes.project) {
                usersToInviteAndAsign.forEach(user => {
                    myFetch(`/users/invite`, {
                        method: 'post', body: {
                            data: { email: user },
                            projects: [projectRes.project.id],
                            comapnyId: companyId
                        }
                    }).then(res => {
                    }).catch(err => {
                        console.log(err)
                    })
                })
            }

        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            if (err && err.message && err.message === "Binder with that code already exists") {
                dispatch(AlertActions.warning("Binder with that code already exists"))
            } else if (err && err.message && err.message === "Binder with that name already exists") {
                dispatch(AlertActions.warning("Binder with that name already exists"))
            } else {
                dispatch(AlertActions.setError(err));
                dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."))
            }

        })
    }
}

function deleteProject(projectId) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })

        myFetch(`/projects/delete-project/${projectId}`, { method: 'delete' }).then(res => {
            if (res.success) {
                dispatch({ type: ProjectCostsConsts.DELETE_PROJECT, projectId: projectId })
                dispatch({ type: CompanyConst.DELETE_PROJECT, projectId: projectId })
                dispatch({ type: ReportsConst.DELETE_PROJECT, projectId: projectId })
                dispatch({ type: EventConst.DELETE_PROJECT, projectId: projectId })
                dispatch({ type: FileConst.DELETE_PROJECT, projectId: projectId })
                dispatch({ type: MessageConst.DELETE_PROJECT, projectId: projectId })
                dispatch({ type: TransferConst.DELETE_PROJECT, projectId: projectId })
                dispatch({ type: UserConst.DELETE_PROJECT, projectId: projectId })

                dispatch(AlertActions.info("Binder has been deleted"))
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            console.log(err)
            handleError(err, dispatch, `/projects/delete-project/${projectId}`)
        })
    }
}

function renameProject(password, projectId, newName, newCode, codeEdited, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/projects/rename-project`, {
            method: 'put', body: {
                password,
                projectId,
                newName,
                newCode,
                codeEdited
            }
        }).then(res => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            cb(res.success)
            if (res.success) {
                dispatch(AlertActions.info("Binder has been renamed"))
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            cb(false)
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/projects/rename-project`)
        })
    }
}

function handleDeleteProjectSocketAcction(data, project) {
    return dispatch => {
        dispatch({ type: ProjectCostsConsts.DELETE_PROJECT, projectId: data.projectId })
        dispatch({ type: CompanyConst.DELETE_PROJECT, projectId: data.projectId })
        dispatch({ type: ReportsConst.DELETE_PROJECT, projectId: data.projectId })
        dispatch({ type: EventConst.DELETE_PROJECT, projectId: data.projectId })
        dispatch({ type: FileConst.DELETE_PROJECT, projectId: data.projectId })
        dispatch({ type: MessageConst.DELETE_PROJECT, projectId: data.projectId })
        dispatch({ type: TransferConst.DELETE_PROJECT, projectId: data.projectId })
        dispatch({ type: UserConst.DELETE_PROJECT, projectId: data.projectId })

        var messageId = randomString({
            length: 32,
            numeric: true,
            letters: true,
            special: true,
        })
        messageId += moment().format("YYYYMMDDHHmmssSSS")
        var text = 'Deleted project'
        var code
        if (project && project.code) {
            code = project.code
        }

        let message = {
            id: messageId,
            user: data.user,
            project: code,
            time: moment(),
            text: text,
            file: '',
            type: 'deletedProject',
            seen: false,
            fileId: -1,
            projectId: -1,
            latest: true,
        }

        dispatch({ type: MessageConst.ADD_MESSAGE, message: message })

    }
}

function getAllProjects() {
    return dispatch => {
        myFetch(`/projects/superadmin`, { method: 'get' }).then(res => {
            if (res.success) {
                dispatch({ type: UserConst.GET_ALL_PROJECTS, projects: res.projects })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(error => {
            console.error(error)
            dispatch(AlertActions.setError(error));
            dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."))
        })
    }
}

function getUserProjects() {
    return dispatch => {
        myFetch(`/user-projects/my-projects`, { method: 'get' }).then(result => {
            dispatch({ type: UserConst.GET_USER_PROJECTS, userProjects: result.userProjects, groupedProjects: result.groupedProjects })
        }).catch(err => {
            handleError(err, dispatch, `/user-projects/my-projects`)
        })
    }
}

function addCompany(data) {
    return dispatch => {
        myFetch(`/users/add-company`, {
            method: 'post', body: {
                companyInfo: {
                    companyName: data.companyName,
                    taxId: data.taxId,
                    email: data.email,
                    type: data.type.toUpperCase().replace(/^"(.*)"$/, '$1'),
                    code: data.code
                }
            }
        }).then(res => {
            console.log(res)
            history.push(routes.panel.superAdmin.companies)
            dispatch(AlertActions.info("New pratner was invited to join EasyDocs."))
        }).catch(err => {
            if (err && err.message && err.message.includes("`email` Email already exists")) {
                dispatch(AlertActions.danger("User with that e-mail address already exists."))
            } else {
                dispatch(AlertActions.setError(err));
                dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."))
            }
        })
    }
}

function toggleFavouriteForProject(projectId, favourite) {
    return dispatch => {
        dispatch({ type: UserConst.TOGGLE_FAVOURITE_FOR_PROJECT, projectId: projectId, favourite: favourite })
        dispatch({ type: QuickActionsConsts.TOGGLE_FAVOURITE_FOR_PROJECT_GROUP, projectId: projectId, favourite: favourite })
        myFetch(`/user-projects/toggle-favourite`, {
            method: 'put', body: {
                projectId: projectId,
                favourite: favourite
            }
        }).then(res => {
            if (!res.success) {
                dispatch({ type: UserConst.TOGGLE_FAVOURITE_FOR_PROJECT, projectId: projectId, favourite: !favourite })
                dispatch({ type: QuickActionsConsts.TOGGLE_FAVOURITE_FOR_PROJECT_GROUP, projectId: projectId, favourite: !favourite })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({ type: UserConst.TOGGLE_FAVOURITE_FOR_PROJECT, projectId: projectId, favourite: !favourite })
            dispatch({ type: QuickActionsConsts.TOGGLE_FAVOURITE_FOR_PROJECT_GROUP, projectId: projectId, favourite: !favourite })
            handleError(err, dispatch, `/user-projects/toggle-favourite`)
        })
    }
}

function switchDashboardViewModel(project) {
    return dispatch => {
        if (project) {
            dispatch({ type: UserConst.SWITCH_DASHBOARD_VIEW_MODEL, project: project })
            dispatch({ type: FileConst.TOGGLE_PROJECT_FILES, extend: true, projectId: project })
            dispatch({ type: ProjectCostsConsts.EXTEND_PROJECT_COSTS, projectId: project, extended: true })
        } else {
            dispatch({ type: UserConst.SWITCH_DASHBOARD_VIEW_MODEL, project: null })
            dispatch({ type: FileConst.HIDE_ALL_PROJECT_FILES })
            dispatch({ type: TransferConst.HIDE_ALL_PROJECT_PAYMENT_ORDERS })
            dispatch({ type: ProjectCostsConsts.HIDE_ALL_PROJECT_COSTS })
        }
    }
}

function setCurrentProject(id) {
    return dispatch => {
        dispatch({ type: UserConst.SET_CURRENT_PROJECT, project: id })
    }
}

function toggleNotifyAboutEvents() {
    return dispatch => {
        dispatch({ type: UserConst.TOGGLE_NOTIFY, notifyType: 'events' })
        myFetch(`/users/toggle-notifications`, {
            method: 'put', body: {
                type: 'events'
            }
        }).then(res => {
            if (!res.success) {
                dispatch({ type: UserConst.TOGGLE_NOTIFY, notifyType: 'events' })
            }
        }).catch(err => {
            dispatch({ type: UserConst.TOGGLE_NOTIFY, notifyType: 'events' })
            handleError(err, dispatch, `/users/toggle-notifications`)
        })
    }
}

function toggleNotifyAboutDocs() {
    return dispatch => {
        dispatch({ type: UserConst.TOGGLE_NOTIFY, notifyType: 'docs' })
        myFetch(`/users/toggle-notifications`, {
            method: 'put', body: {
                type: 'docs'
            }
        }).then(res => {
            if (!res.success) {
                dispatch({ type: UserConst.TOGGLE_NOTIFY, notifyType: 'docs' })
            }
        }).catch(err => {
            dispatch({ type: UserConst.TOGGLE_NOTIFY, notifyType: 'docs' })
            handleError(err, dispatch, `/users/toggle-notifications`)
        })
    }
}

function toggleNotifyAboutPayments() {
    return dispatch => {
        dispatch({ type: UserConst.TOGGLE_NOTIFY, notifyType: 'payments' })
        myFetch(`/users/toggle-notifications`, {
            method: 'put', body: {
                type: 'payments'
            }
        }).then(res => {
            if (!res.success) {
                dispatch({ type: UserConst.TOGGLE_NOTIFY, notifyType: 'payments' })
            }
        }).catch(err => {
            dispatch({ type: UserConst.TOGGLE_NOTIFY, notifyType: 'payments' })
            handleError(err, dispatch, `/users/toggle-notifications`)
        })
    }
}

function toggleNotifyAboutUrgentDocs() {
    return dispatch => {
        dispatch({ type: UserConst.TOGGLE_NOTIFY, notifyType: 'urgentDocs' })
        myFetch(`/users/toggle-notifications`, {
            method: 'put', body: {
                type: 'urgentDocs'
            }
        }).then(res => {
            if (!res.success) {
                dispatch({ type: UserConst.TOGGLE_NOTIFY, notifyType: 'urgentDocs' })
            }
        }).catch(err => {
            dispatch({ type: UserConst.TOGGLE_NOTIFY, notifyType: 'urgentDocs' })
            handleError(err, dispatch, `/users/toggle-notifications`)
        })
    }
}

function changeMontOnDashboardCalendar(date) {
    return dispatch => {
        dispatch({ type: UserConst.CHANGE_MONTH_ON_DASHBOARD_CALENDAR, date: date })
    }
}

function toogleDayForTooltipCalendar(date) {
    return dispatch => {
        dispatch({ type: UserConst.TOOGLE_DAY_ON_DASHBOARD_CALENDAR, date: date })
    }
}

function submitTicket(ticket) {
    return dispatch => {
        dispatch({ type: UserConst.SEND_TICKET_REQUEST })
        myFetch(`/users/submit-ticket`, {
            method: 'post', body: {
                ticket: ticket
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: UserConst.GOT_TICKET_RESPONSE, success: true })
                dispatch(AlertActions.info("Thank you for leaving your feadback"))
            } else {
                dispatch({ type: UserConst.GOT_TICKET_RESPONSE, success: false })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({ type: UserConst.GOT_TICKET_RESPONSE, success: false })
            handleError(err, dispatch, `/users/submit-ticket`)
        })
    }
}

function sortAllUsersForSuperadmin(field, order) {
    return dispatch => {
        dispatch({ type: UserConst.SORT_ALL_USERS, field: field, order: order })
    }
}

function getEmailsThatSentFiles(projectId, cb) {
    return dispatch => {
        myFetch(`/projects/get-emails-that-sent-files/${projectId}`, {
            method: 'get'
        }).then(res => {
            cb(res.success, res.emailsArray)
        }).catch(err => {
            cb(false, [])
            handleError(err, dispatch, `/projects/get-emails-that-sent-files/${projectId}`)
        })
    }
}

function getFileCountsForProject(project) {
    return dispatch => {
        dispatch({ type: UserConst.GET_FILE_COUNTS_FOR_PROJECT, project: project })
    }
}

function switchFileManagerViewMode(mode) {
    return dispatch => {
        dispatch({ type: UserConst.SWITCH_FILE_MANAGER_VIEW_MODE, mode: mode })
    }
}

function toggleOverlay(preferances) {
    return dispatch => {
        dispatch({ type: UserConst.SET_OVERLAY_PREFERANCE, preferances: preferances })
        localStorage.setItem('overlayPreferance', JSON.stringify(preferances))
    }
}

function setWhatsNewVersion(version) {
    return dispatch => {
        dispatch({ type: UserConst.SET_WHATS_NEW_VERSION, version })
    }
}

function sendEmailsAsSuperadmin(receiversGroup, subject, content, password) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/superadmin-emails/send-emails-as-superadmin`, {
            method: 'post', body: {
                receiversGroup: receiversGroup,
                subject: subject,
                content: content,
                password: password
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.success("Emails have been sent"))
                dispatch({ type: UserConst.SENT_NEW_EMAIL_AS_SUPERADMIN, email: res.emailInstance })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/superadmin-emails/send-emails-as-superadmin`)
        })
    }
}

function getAllInformationEmails() {
    return dispatch => {
        myFetch(`/superadmin-emails/get-all-for-superadmin`, { method: 'get' }).then(res => {
            if (res.success) {
                dispatch({ type: UserConst.GET_ALL_EMAILS_AS_SUPERADMIN, emails: res.emails })
            }
        }).catch(err => {
            handleError(err, dispatch, `/superadmin-emails/send-emails-as-superadmin`)
        })
    }
}

function getAllEasyDocsPayments() {
    return dispatch => {
        myFetch(`/subscription-fees/get-all-for-superadmin`, { method: 'get' }).then(res => {
            if (res.success) {
                dispatch({ type: UserConst.GET_ALL_ED_PAYMENTS_AS_SUPERADMIN, payments: res.payments })
            }
        }).catch(err => {
            handleError(err, dispatch, `/subscription-fees/send-emails-as-superadmin`)
        })
    }
}

function getTransactionDetails(period) {
    return dispatch => {
        dispatch({ type: UserConst.CLEAR_DETAIL_PAYMENTS_AS_SUPERADMIN })
        myFetch(`/subscription-fees/get-detailed-report-for-superadmin?period=${period}`, { method: 'get' }).then(res => {
            if (res.success) {
                dispatch({ type: UserConst.GET_DETAIL_PAYMENTS_AS_SUPERADMIN, payments: res.payments })
            }
        }).catch(err => {
            handleError(err, dispatch, `/subscription-fees/send-emails-as-superadmin`)
        })
    }
}

function getEasyDocsPaymentsReport() {
    return dispatch => {
        myFetch(`/subscription-fees/get-report-for-superadmin`, { method: 'get' }).then(res => {
            if (res.success) {
                dispatch({ type: UserConst.GET_ED_PAYMENTS_REPORT_FOR_SUPERADMIN, report: res.report, years: res.years, total: res.total })
            }
        }).catch(err => {
            handleError(err, dispatch, `/subscription-fees/get-report-for-superadmin`)
        })
    }
}

function getMarketingReport() {
    return dispatch => {
        myFetch(`/subscription-fees/get-marketing-report-for-superadmin`, { method: 'get' }).then(res => {
            if (res.success) {
                dispatch({ type: UserConst.GET_MARKETING_REPORT_FOR_SUPERADMIN, weekData: res.weekData, monthData: res.monthData, yearData: res.yearData })
            }
        }).catch(err => {
            handleError(err, dispatch, `/subscription-fees/get-marketing-report-for-superadmin`)
        })
    }
}

function sortAllEDPaymentsForSuperadmin(field, order) {
    return dispatch => {
        dispatch({ type: UserConst.SORT_ALL_ED_PAYMENTS_FOR_SUPERADMIN, field: field, order: order })
    }
}

function downloadEDPaymentInvoice(txId, fileName, cb) {
    return dispatch => {
        myFetch(`/subscription-fees/download/${txId}`, { method: 'get' })
            .then(res => {
                if (res.success) {
                    var dLink = document.createElement('a')
                    dLink.download = res.name
                    dLink.href = res.data
                    dLink.click()
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
                cb()
            }).catch(err => {
                handleError(err, dispatch, `/subscription-fees/download/${txId}`)
                cb()
            })
    }
}

function getWhatsNewList() {
    return dispatch => {
        myFetch(`/whats-new/get-all-for-superadmin`, { method: 'get' }).then(res => {
            if (res.success) {
                dispatch({ type: UserConst.GET_WHATS_NEW_FOR_SUPERADMIN, list: res.list })
            }
        }).catch(err => {
            handleError(err, dispatch, `/whats-new/get-all-for-superadmin`)
        })
    }
}

function createWhatsNew(data, cb) {
    return dispatch => {
        myFetch('/whats-new/create', {
            method: 'POST',
            body: JSON.stringify({
                ...data
            })
        }).then(res => {
            cb(res.success)

            if (!res.success) {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            cb(false)
            handleError(err, dispatch, `/whats-new/creat`)
        })
    }
}

function sortAllInformationEmails(field, order) {
    return dispatch => {
        dispatch({ type: UserConst.SORT_ALL_EMAILS_FOR_SUPERADMIN, field: field, order: order })
    }
}

function turnOn2FA() {
    return dispatch => {
        dispatch({ type: UserConst.TOGGLE_2FA, enabled: true })
        myFetch(`/users/enable-2fa`, { method: 'put' })
            .then(res => {
                if (!res.success) {
                    dispatch({ type: UserConst.TOGGLE_2FA, enabled: false })
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                dispatch({ type: UserConst.TOGGLE_2FA, enabled: false })
                handleError(err, dispatch, `/users/toggle-notifications`)
            })
    }
}

function turnOff2FA(token) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/users/disable-2fa`, {
            method: 'put', body: {
                token: token,
                time: moment().unix()
            }
        })
            .then(res => {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                if (res.success) {
                    dispatch({ type: UserConst.TOGGLE_2FA, enabled: false })
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                handleError(err, dispatch, `/users/toggle-notifications`)
            })
    }
}

function sendEasyGuardDeviceResetEmail(email, cb) {
    return dispatch => {
        myFetch(`/users/send-easyguard-device-reset-email`, {
            method: 'post', body: {
                email: email.toLowerCase()
            }
        })
            .then(res => {
                cb()
                if (res.success) {
                    dispatch({ type: UserConst.HIDE_ASK_FOR_EASYGUARD })
                    dispatch(AlertActions.info(res.msg, 10000))
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                cb()
                handleError(err, dispatch, `/users/send-easyguard-device-reset-email`)
            })
    }
}

function resetEasyGuardDevice(email, token, cb) {
    return dispatch => {
        myFetch(`/users/reset-easyguard-device`, {
            method: 'put', body: {
                email: email.toLowerCase(),
                token: token
            }
        })
            .then(res => {
                cb()
                if (res.success) {
                    dispatch({ type: UserConst.RESET_EASYGUARD_DEVICE })
                }
            }).catch(err => {
                cb()
                handleError(err, dispatch, `/users/reset-easyguard-device`)
            })
    }
}

function logoutAllDevices() {
    return dispatch => {
        myFetch(`/users/logout-from-all-devices`, { method: 'post' })
            .then(res => {
                if (res.success) {
                    dispatch({ type: UserConst.LOGGED_FALSE })
                    dispatch({ type: UserConst.LOGOUT })
                    dispatch({ type: EventConst.LOGOUT })
                    dispatch({ type: CompanyConst.LOGOUT })
                    dispatch({ type: ReportsConst.LOGOUT })
                    dispatch({ type: FileConst.LOGOUT })
                    history.push(routes.login)
                }
            }).catch(err => {
                handleError(err, dispatch, `/users/logout-from-all-devices`)
            })
    }
}

function logoutAllDevicesSocketHandler() {
    return dispatch => {
        dispatch({ type: UserConst.LOGGED_FALSE })
        dispatch({ type: UserConst.LOGOUT })
        dispatch({ type: EventConst.LOGOUT })
        dispatch({ type: CompanyConst.LOGOUT })
        dispatch({ type: ReportsConst.LOGOUT })
        dispatch({ type: FileConst.LOGOUT })
    }
}

function registerEasyGuardDevice(deviceName) {
    return dispatch => {
        dispatch({ type: UserConst.REGISTER_EASYGUARD_DEVICE, deviceName: deviceName })
    }
}

function enable2FASocketHandler() {
    return dispatch => {
        dispatch({ type: UserConst.TOGGLE_2FA, enabled: true })
    }
}

function disable2FASocketHandler() {
    return dispatch => {
        dispatch({ type: UserConst.TOGGLE_2FA, enabled: false })
    }
}

function toggleQuickAction(actionType, show) {
    return dispatch => {
        document.body.style.overflow = show ? 'hidden' : ''
        if (actionType === availableQuickActions.bankTransfers)
            dispatch({ type: QuickActionsConsts.TOGGLE_BANK_TRANSFERS, show: show })
        if (actionType === availableQuickActions.docsExport)
            dispatch({ type: QuickActionsConsts.TOGGLE_DOCS_EXPORT, show: show })
        if (actionType === availableQuickActions.expensesAssistant)
            dispatch({ type: QuickActionsConsts.TOGGLE_EXPENSES_ASSISTANT, show: show })
        if (actionType === availableQuickActions.docsRequiringMyAttention)
            dispatch({ type: QuickActionsConsts.TOGGLE_DOCS_REQUIRING_ATTENTION_ASSISTANT, show: show })

    }
}

function saveCustomTableColumns(columns, cb) {
    return dispatch => {
        myFetch(`/users/save-custom-table-columns`, {
            method: 'put', body: {
                columns: columns
            }
        }).then(res => {
            cb(res.success)
            if (res.success) {
                dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: { custom_table_columns: columns } })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            cb(false)
            handleError(err, dispatch, `/users/finish-admin-onboarding`)
        })
    }
}

function getDocsRequiringMyAttention(projectId = -1) {
    return dispatch => {
        dispatch({ type: QuickActionsConsts.GET_DOCS_REQUIRING_ATTENTION_REQUEST, projectId: projectId })
        myFetch(`/users/docs-requiring-my-attention/${projectId}`, {
            method: 'get'
        }).then(res => {
            if (res.success) {
                dispatch({
                    type: QuickActionsConsts.GET_DOCS_REQUIRING_ATTENTION,
                    projectId: projectId,
                    dropzoneDocs: res.dropzoneDocs,
                    additionalPaymentAcceptanceDocs: res.additionalPaymentAcceptanceDocs,
                    upcomingPayments: res.upcomingPayments,
                    mentionedUnreadConversaons: res.mentionedUnreadConversaons
                })
            } else {
                dispatch({
                    type: QuickActionsConsts.GET_DOCS_REQUIRING_ATTENTION,
                    projectId: projectId,
                    dropzoneDocs: [],
                    additionalPaymentAcceptanceDocs: [],
                    upcomingPayments: [],
                    mentionedUnreadConversaons: []
                })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({
                type: QuickActionsConsts.GET_DOCS_REQUIRING_ATTENTION,
                projectId: projectId,
                dropzoneDocs: [],
                additionalPaymentAcceptanceDocs: [],
                upcomingPayments: [],
                mentionedUnreadConversaons: []
            })
            handleError(err, dispatch, `/users/finish-admin-onboarding`)
        })
    }
}

function collapseDocsRequiringAttentionsProjectGroup(docsList, groupIndex) {
    return dispatch => {
        dispatch({ type: QuickActionsConsts.COLLAPSE_DOCS_REQUIRING_ATTENTION_PROJECT_GROUP, docsList: docsList, groupIndex: groupIndex })
    }
}

function setSortFieldForDocsRequiringAttention(field, projectId, docsList) {
    return dispatch => {
        dispatch({ type: QuickActionsConsts.SET_SORT_FIELD_DOCS_REQUIRING_ATTENTION, field: field, projectId: projectId, docsList: docsList })
    }
}

function toggleNotificationSettingForProject(projectId, notificationType, newValue) {
    return dispatch => {
        dispatch({ type: UserConst.TOOGLE_NOTIFICATIONS_FOR_PROJECT, projectId: projectId, notificationType: notificationType, newValue: newValue })
        myFetch(`/user-projects/toggle-notification`, {
            method: 'put', body: {
                projectId: projectId,
                notificationType: notificationType,
                newValue: newValue,
            }
        }).then(res => {
            if (!res.success) {
                dispatch({ type: UserConst.TOOGLE_NOTIFICATIONS_FOR_PROJECT, projectId: projectId, notificationType: notificationType, newValue: !newValue })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({ type: UserConst.TOOGLE_NOTIFICATIONS_FOR_PROJECT, projectId: projectId, notificationType: notificationType, newValue: !newValue })
            handleError(err, dispatch, `/user-projects/toggle-notification`)
        })
    }
}

function sendErrorEmail(error) {
    return dispatch => {
        myFetch(`/users/send-error-email`, {
            method: 'post', body: {
                error: error
            }
        })
    }
}


function handleError(error, dispatch, path) {
    if (error?.code === 'AUTHORIZATION_REQUIRED' && localStorage.getItem('dashboardProject')) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        dispatch({ type: UserConst.LOGGED_FALSE })
        dispatch({ type: UserConst.LOGOUT })
        dispatch({ type: EventConst.LOGOUT })
        dispatch({ type: CompanyConst.LOGOUT })
        dispatch({ type: ReportsConst.LOGOUT })
        dispatch({ type: FileConst.LOGOUT })
        dispatch(AlertActions.info("You have been logged out.", 5000))
    } else {
        dispatch(AlertActions.setError(error, path));
        dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."));
    }
}
