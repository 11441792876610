import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TagsComponent from '../TagsComponent';
import OcrPageWaproNettoAccounts from '../wapro/OcrPageWaproNettoAccounts'
import MpkListSelector from './MpkListSelector'

import { FileActions } from '../../../actions/FileActions';
import { AlertActions } from '../../../actions/AlertActions';

import mpkLevel1 from '../../../helpers/MpkSplitWineAvenue/mpkLevel1List.json'
import mpkLevel2 from '../../../helpers/MpkSplitWineAvenue/mpkLevel2List.json'
import mpkLevel3 from '../../../helpers/MpkSplitWineAvenue/mpkLevel3List.json'
import mpkLevel4 from '../../../helpers/MpkSplitWineAvenue/mpkLevel4List.json'

export class CostSplit extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        const { isInDocPreview } = this.props
        if (!isInDocPreview) {
            this.addFirstMpkSplit()
        }
    }

    componentDidUpdate(prevProps) {
        const { totalNetAmount } = this.props
        var mpkSplitWineAvenue = [...this.props.mpkSplitWineAvenue]

        if (totalNetAmount !== prevProps.totalNetAmount && mpkSplitWineAvenue?.length === 1) {
            mpkSplitWineAvenue[0].netAmount = totalNetAmount
            this.props.setMpkSplit(mpkSplitWineAvenue)

            if (mpkSplitWineAvenue[0].id > 0 && this.props.fileId) {
                this.props.editMpkSplitInstance(this.props.fileId, this.props.ftvId, mpkSplitWineAvenue[0].id, mpkSplitWineAvenue[0])
            }
        }
    }

    componentWillUnmount() {
    }

    onNetChange = (e, type, index) => {
        var mpkSplitWineAvenue = [...this.props.mpkSplitWineAvenue]
        var { value } = e.target
        value = value.replace(/[^0-9,.-]/gm, '').replace(',', '.')
        mpkSplitWineAvenue[index].netAmount = value
        this.props.setMpkSplit(mpkSplitWineAvenue)
    }

    selectMpk = (index, level, value) => {
        var mpkSplitWineAvenue = [...this.props.mpkSplitWineAvenue]
        var mpkDescriptionField = `mpkLevel${level}`
        var mpkCodeField = `mpkCodeLevel${level}`
        if (mpkSplitWineAvenue[index][mpkCodeField] !== value.code) {
            mpkSplitWineAvenue[index][mpkDescriptionField] = value.description
            mpkSplitWineAvenue[index][mpkCodeField] = value.code
        } else {
            mpkSplitWineAvenue[index][mpkDescriptionField] = ''
            mpkSplitWineAvenue[index][mpkCodeField] = ''
        }
        this.props.setMpkSplit(mpkSplitWineAvenue)

        if (mpkSplitWineAvenue[index].id > 0 && this.props.fileId) {
            this.props.editMpkSplitInstance(this.props.fileId, this.props.ftvId, mpkSplitWineAvenue[index].id, mpkSplitWineAvenue[index])
        }
    }

    setWaproNetAccount = (value, index) => {
        var mpkSplitWineAvenue = [...this.props.mpkSplitWineAvenue]
        mpkSplitWineAvenue[index].waproNetAccount = value
        this.props.setMpkSplit(mpkSplitWineAvenue)

        if (mpkSplitWineAvenue[index].id > 0 && this.props.fileId) {
            this.props.editMpkSplitInstance(this.props.fileId, this.props.ftvId, mpkSplitWineAvenue[index].id, mpkSplitWineAvenue[index])
        }
    }

    onFieldBlur = index => {
        var mpkSplitWineAvenue = [...this.props.mpkSplitWineAvenue]
        if (mpkSplitWineAvenue[index].id > 0 && this.props.fileId) {
            this.props.editMpkSplitInstance(this.props.fileId, this.props.ftvId, mpkSplitWineAvenue[index].id, mpkSplitWineAvenue[index])
        }
    }

    addMpkSplit = () => {
        var mpkSplitWineAvenue = [...this.props.mpkSplitWineAvenue]
        mpkSplitWineAvenue.push({
            id: -1,
            netAmount: '',
            mpkLevel1: '',
            mpkCodeLevel1: '',
            mpkLevel2: '',
            mpkCodeLevel2: '',
            mpkLevel3: '',
            mpkCodeLevel3: '',
            mpkLevel4: '',
            mpkCodeLevel4: '',
            waproNetAccount: ''
        })
        var newSplitIndex = mpkSplitWineAvenue.length - 1

        this.props.setMpkSplit(mpkSplitWineAvenue)

        if (this.props.fileId) {
            this.props.addMpkSplitInstance(this.props.fileId, this.props.ftvId, null, splitId => {
                if (splitId) {
                    mpkSplitWineAvenue[newSplitIndex].id = splitId
                    this.props.setMpkSplit(mpkSplitWineAvenue)
                }
            })
        }
    }

    addFirstMpkSplit = () => {
        const { mpkSplitWineAvenue, totalNetAmount } = this.props
        if (mpkSplitWineAvenue?.length) {
            var mpkSplitWineAvenueCopy = []
            mpkSplitWineAvenueCopy.push({
                id: -1,
                netAmount: totalNetAmount || '',
                mpkLevel1: '',
                mpkCodeLevel1: '',
                mpkLevel2: '',
                mpkCodeLevel2: '',
                mpkLevel3: '',
                mpkCodeLevel3: '',
                mpkLevel4: '',
                mpkCodeLevel4: '',
                waproNetAccount: ''
            })
            this.props.setMpkSplit(mpkSplitWineAvenueCopy)
        }

        if (this.props.fileId) {
            this.props.addFirstMpkSplitInstance(this.props.fileId, this.props.ftvId, totalNetAmount || '', mpkSplit => {
                this.props.setMpkSplit(mpkSplit)
            })
        }
    }

    deleteCostSplit = (index) => {
        var mpkSplitWineAvenue = [...this.props.mpkSplitWineAvenue]
        const splitToDelete = mpkSplitWineAvenue[index]
        mpkSplitWineAvenue.splice(index, 1)

        this.props.setMpkSplit(mpkSplitWineAvenue)

        if (splitToDelete.id > 0 && this.props.fileId) {
            this.props.deleteMpkSplitInstance(this.props.fileId, this.props.ftvId, splitToDelete.id)
        }
    }

    showDevideEqually = () => {
        this.setState({
            showDivideEquallyWindow: true,
            divideEquallyInput: ''
        })
    }

    divideEqually = () => {
        const { divideEquallyInput } = this.state
        if (!divideEquallyInput) {
            this.props.alertWarn(this.context.t('You must specify how many parts the cost is to be divided into'))
        } else {
            var divideEquallyNumber = parseFloat(divideEquallyInput)
            if (divideEquallyNumber > 30) {
                this.props.alertWarn(this.context.t('You cannot divide the cost into more than {n} parts', { n: 30 }))
            } else if (divideEquallyNumber < 2) {
                this.props.alertWarn(this.context.t('You must divide it into at least 2 parts'))
            } else {
                const { totalNetAmount } = this.props
                if (totalNetAmount) {
                    var netPart = parseFloat(parseFloat(totalNetAmount) / divideEquallyNumber).toFixed(2)
                    var netDiff = 0
                    if (parseFloat(netPart) * divideEquallyNumber !== parseFloat(totalNetAmount)) {
                        netDiff = parseFloat(totalNetAmount) - parseFloat(netPart) * divideEquallyNumber
                    }

                    var mpkSplitWineAvenue = [...this.props.mpkSplitWineAvenue]
                    for (let i = 0; i < divideEquallyNumber; i++) {
                        if (i === 0) {
                            var firstNetPart = parseFloat(parseFloat(netPart) + parseFloat(netDiff)).toFixed(2)
                            mpkSplitWineAvenue[i].netAmount = firstNetPart
                            this.props.setMpkSplit(mpkSplitWineAvenue)
                            this.props.editMpkSplitInstance(this.props.fileId, this.props.ftvId, mpkSplitWineAvenue[i].id, mpkSplitWineAvenue[i])
                        } else if (mpkSplitWineAvenue[i]) {
                            mpkSplitWineAvenue[i].netAmount = netPart
                            this.props.setMpkSplit(mpkSplitWineAvenue)
                            this.props.editMpkSplitInstance(this.props.fileId, this.props.ftvId, mpkSplitWineAvenue[i].id, mpkSplitWineAvenue[i])
                        } else {
                            mpkSplitWineAvenue.push({
                                id: -1,
                                netAmount: netPart,
                                mpkLevel1: '',
                                mpkCodeLevel1: '',
                                mpkLevel2: '',
                                mpkCodeLevel2: '',
                                mpkLevel3: '',
                                mpkCodeLevel3: '',
                                mpkLevel4: '',
                                mpkCodeLevel4: '',
                                waproNetAccount: ''
                            })
                            this.props.addMpkSplitInstance(this.props.fileId, this.props.ftvId, {
                                netAmount: netPart,
                            }, splitId => {
                                if (splitId) {
                                    mpkSplitWineAvenue[i].id = splitId
                                    this.props.setMpkSplit(mpkSplitWineAvenue)
                                }
                            })
                        }
                    }

                    if (divideEquallyNumber < mpkSplitWineAvenue.length) {
                        var newMpkSplitAllocation = []
                        for (let j = 0; j < mpkSplitWineAvenue.length; j++) {
                            if (j >= divideEquallyNumber && mpkSplitWineAvenue[j]) {
                                this.props.deleteMpkSplitInstance(this.props.fileId, this.props.ftvId, mpkSplitWineAvenue[j].id)
                            } else {
                                newMpkSplitAllocation.push(mpkSplitWineAvenue[j])
                            }
                        }
                        this.props.setMpkSplit(newMpkSplitAllocation)
                    }

                    this.setState({
                        showDivideEquallyWindow: false,
                        divideEquallyInput: ''
                    })
                } else {
                    this.props.alertWarn(this.context.t('First, enter the net amount for the entire document'))
                }
            }
        }
    }

    onDivideInputChange = (e) => {
        var { value } = e.target
        value = value.replace(/[^0-9]/gm, '')

        this.setState({
            divideEquallyInput: value
        })
    }

    render() {
        const {
            showDivideEquallyWindow, divideEquallyInput
        } = this.state
        const {
            customTitle,
            mpkSplitWineAvenue,
            isInDocPreview, readOnly,
            isAccountantInCurrentCompany, companyId,
            currency, paid
        } = this.props

        return (
            <div className={`mpk-split-container ${currency !== 'PLN' ? 'other-currency' : ''} ${paid ? 'paid' : ''}`}>
                {
                    showDivideEquallyWindow ? (
                        <div className="divide-equally-window popup">
                            <div className="popup-card">
                                <h5>{this.context.t('How many parts should the cost be divided into?')}</h5>
                                <input
                                    type="text"
                                    value={divideEquallyInput}
                                    name={`divideEquallyInput`}
                                    onChange={this.onDivideInputChange}
                                />

                                <div className="action-buttons">
                                    <div className="button cancel" onClick={() => this.setState({ showDivideEquallyWindow: false, divideEquallyInput: '' })}>{this.context.t('Cancel')}</div>
                                    <div className="button save" onClick={() => this.divideEqually()}>{this.context.t('Accept')}</div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                <div className="cost-split-header">
                    <h4>{this.context.t(customTitle || 'Split payment into MPK\'s')}:</h4>
                    {
                        !readOnly ? (
                            <>
                                <div className="add-allocation" onClick={() => this.showDevideEqually()}>{this.context.t('Divide into x equal parts')}</div>
                                <div className="add-allocation" onClick={() => this.addMpkSplit()}><span>+</span>{this.context.t('Add MPK')}</div>
                            </>
                        ) : null
                    }
                </div>
                {
                    (mpkSplitWineAvenue || []).map((mpk, i) => {
                        return (
                            <div key={mpk.id > 0 ? `mpk-split-${mpk.id}` : `mpk-split-${i}`} className="cost-split">

                                <div className={`form-group amount ${isAccountantInCurrentCompany ? 'accontant' : ''}`}>
                                    <label>{this.context.t('Net amount')}</label>
                                    <input
                                        type="text"
                                        value={mpk.netAmount}
                                        name={`net-amount-${i}`}
                                        onChange={e => this.onNetChange(e, 'NET', i)}
                                        onBlur={e => this.onFieldBlur(i)}
                                        readOnly={readOnly}
                                    />
                                </div>

                                {
                                    isAccountantInCurrentCompany ? (
                                        <OcrPageWaproNettoAccounts
                                            selectedOption={mpk.waproNetAccount}
                                            select={value => this.setWaproNetAccount(value, i)}
                                            companyId={companyId}
                                            readOnly={readOnly}
                                        />
                                    ) : null
                                }

                                <MpkListSelector
                                    list={mpkLevel1}
                                    mpkLevel={1}
                                    selectedOption={mpk.mpkLevel1}
                                    selectOption={value => this.selectMpk(i, 1, value)}
                                    readOnly={readOnly}
                                />

                                <MpkListSelector
                                    list={mpkLevel2}
                                    mpkLevel={2}
                                    selectedOption={mpk.mpkLevel2}
                                    selectOption={value => this.selectMpk(i, 2, value)}
                                    readOnly={readOnly}
                                />

                                <MpkListSelector
                                    list={mpkLevel3}
                                    mpkLevel={3}
                                    selectedOption={mpk.mpkLevel3}
                                    selectOption={value => this.selectMpk(i, 3, value)}
                                    readOnly={readOnly}
                                />

                                <MpkListSelector
                                    list={mpkLevel4}
                                    mpkLevel={4}
                                    selectedOption={mpk.mpkLevel4}
                                    selectOption={value => this.selectMpk(i, 4, value)}
                                    readOnly={readOnly}
                                />

                                {
                                    mpkSplitWineAvenue.length > 1 && !readOnly ? (
                                        <div className="delete-cost-split" onClick={() => this.deleteCostSplit(i)}><span>-</span>{this.context.t('Delete MPK')}</div>
                                    ) : null
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

CostSplit.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    addMpkSplitInstance: FileActions.addMpkSplitInstance,
    addFirstMpkSplitInstance: FileActions.addFirstMpkSplitInstance,
    deleteMpkSplitInstance: FileActions.deleteMpkSplitInstance,
    editMpkSplitInstance: FileActions.editMpkSplitInstance
}

export default connect(mapStateToProps, mapDispatchToProps)(CostSplit)