import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import { COSTS_AVAILABLE_FIELDS, EXPENSES_AVAILABLE_FIELDS, AGREEMENT_AVAILABLE_FIELDS, CORRESPONDENCE_AVAILABLE_FIELDS, CORPORATE_AVAILABLE_FIELDS, OTHER_AVAILABLE_FIELDS, PROTOCOL_AVAILABLE_FIELDS, AVAILABLE_DEDLINES } from '../../helpers/docflowHelpers'

import { AlertActions } from '../../actions/AlertActions'

export class DocflowStageEditWindow extends Component {

    constructor(props) {
        super(props)
        this.state = {
            itemName: '',
            editingItemName: false,
            selectedUsers: [],
            showUsersList: false,
            requiredUsersCount: 1,
            itemDeadlineId: '',
            itemDeadlineLabel: '',
            canSelectRequiredFields: true,
            requiredFields: [],
            availableFields: [],
            itemDescription: '',
            notifyAboutOvberdue: false,
            canBeRevoked: false,
            canBeSkipped: false,
        }

        this.settingsWindowWrapperRef = React.createRef()
        this.usersWrapperRef = React.createRef()
        this.deadlineWrapperRef = React.createRef()
        this.requiredFieldsWrapperRef = React.createRef()
    }

    componentDidMount() {
        const { docflowDocTypes, editedDocflowItem } = this.props
        console.log(editedDocflowItem)
        this.setState({
            ...editedDocflowItem,
            itemName: this.context.t(editedDocflowItem.title),
        })
        if (editedDocflowItem.itemType === 'verification') {
            if (docflowDocTypes.length === 1) {
                var availableFields = []
                switch (docflowDocTypes[0]?.docType) {
                    case 'COSTS':
                        availableFields = [...COSTS_AVAILABLE_FIELDS]
                        break;
                    case 'EXPENSES':
                        availableFields = [...EXPENSES_AVAILABLE_FIELDS]
                        break;
                    case 'AGREEMENT':
                        availableFields = [...AGREEMENT_AVAILABLE_FIELDS]
                        break;
                    case 'CORRESPONDENCE':
                        availableFields = [...CORRESPONDENCE_AVAILABLE_FIELDS]
                        break;
                    case 'CORPORATE':
                        availableFields = [...CORPORATE_AVAILABLE_FIELDS]
                        break;
                    case 'OTHER':
                        availableFields = [...OTHER_AVAILABLE_FIELDS]
                        break;
                    case 'PROTOCOL':
                        availableFields = [...PROTOCOL_AVAILABLE_FIELDS]
                        break;
                    default:
                        availableFields = [{
                            id: 'all',
                            label: 'All fields'
                        }]
                }

                console.log(availableFields)

                this.setState({
                    availableFields: availableFields
                })
            } else {
                this.setState({
                    canSelectRequiredFields: false,
                    requiredFields: [{
                        id: 'all',
                        label: 'All fields'
                    }]
                })
            }
        }
        document.addEventListener("mousedown", this.handleClick);
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = event => {
        // if (this.settingsWindowWrapperRef && !this.settingsWindowWrapperRef?.current?.contains(event.target)) {
        //     this.props.close()
        // } else
        if (this.state.showUsersList && this.usersWrapperRef && !this.usersWrapperRef.current.contains(event.target)) {
            this.setState({
                showUsersList: false
            })
        } else if (this.state.showDeadlinesList && this.deadlineWrapperRef && !this.deadlineWrapperRef.current.contains(event.target)) {
            this.setState({
                showDeadlinesList: false
            })
        } else if (this.state.showRequiredFieldsList && this.requiredFieldsWrapperRef && !this.requiredFieldsWrapperRef.current.contains(event.target)) {
            this.setState({
                showRequiredFieldsList: false
            })
        }
    }

    onInputChange = e => {
        e.preventDefault()

        var { name, value } = e.target

        this.setState({ [name]: value })
    }

    toggleUsersList = (event) => {
        const clickedElement = event?.target || {};
        const clickedElementClass = clickedElement?.className || '';

        if (clickedElementClass.includes('remove')) {
            console.log('Remove button was clicked');
        } else {
            const { showUsersList } = this.state
            this.setState({
                showUsersList: !showUsersList
            })
        }
    }

    selectDocflowUser = user => {
        const { selectedUsers } = this.state
        var tempSelectedUsers = [...selectedUsers]

        if (tempSelectedUsers.find(u => u.id === user.id)) {
            tempSelectedUsers = tempSelectedUsers.filter(u => u.id !== user.id)
        } else {
            tempSelectedUsers.push(user)
        }


        this.setState({
            selectedUsers: tempSelectedUsers
        })
    }

    removeSelectedUser = user => {
        const { selectedUsers, requiredUsersCount } = this.state
        var tempSelectedUsers = [...selectedUsers]

        tempSelectedUsers = tempSelectedUsers.filter(u => u.id !== user.id)

        this.setState({
            selectedUsers: tempSelectedUsers,
            showUsersList: false,
            requiredUsersCount: requiredUsersCount > tempSelectedUsers.length ? tempSelectedUsers.length : requiredUsersCount
        })
    }

    toggleDeadlinesList = () => {
        const { showDeadlinesList } = this.state
        this.setState({
            showDeadlinesList: !showDeadlinesList
        })
    }

    selectDeadline = deadline => {
        const { editedDocflowItem } = this.props

        this.setState({
            itemDeadlineId: deadline.id,
            itemDeadlineLabel: editedDocflowItem.index === 0 ? deadline.uploadLabel : deadline.label,
            showDeadlinesList: false
        })
    }

    toggleRequiredFieldsList = () => {
        const { showRequiredFieldsList, canSelectRequiredFields } = this.state
        this.setState({
            showRequiredFieldsList: canSelectRequiredFields && !showRequiredFieldsList
        })
    }

    selectRequiredField = field => {
        const { requiredFields, availableFields } = this.state
        var tempRequiredFields = [...requiredFields]

        if (field.id === 'all') {
            tempRequiredFields = [...availableFields]
            tempRequiredFields = tempRequiredFields.filter(f => f.id !== 'all')
        } else {
            if (tempRequiredFields.find(f => f.id === field.id)) {
                tempRequiredFields = tempRequiredFields.filter(f => f.id !== field.id)
            } else {
                tempRequiredFields.push(field)
            }
        }


        this.setState({
            requiredFields: tempRequiredFields
        })
    }

    removeSelectedRequiredField = fieldId => {
        const { requiredFields } = this.state
        var tempRequiredFields = [...requiredFields]

        tempRequiredFields = tempRequiredFields.filter(f => f.id !== fieldId)

        this.setState({
            requiredFields: tempRequiredFields,
            showRequiredFieldsList: false
        })
    }

    saveItem = () => {
        const {
            itemName, selectedUsers, requiredUsersCount, itemDeadlineId, itemDeadlineLabel, requiredFields, availableFields, canSelectRequiredFields, itemDescription,
            notifyAboutOvberdue, canBeRevoked, canBeSkipped
        } = this.state
        const { editedDocflowItem } = this.props
        var isValid = true

        if (selectedUsers.length === 0) {
            isValid = false
            this.props.alertWarn('Add users responsible for the stage')
        }

        // if (editedDocflowItem.itemType === 'verification' && requiredFields.length === 0) {
        //     isValid = false
        //     this.props.alertWarn('Add required fields for the stage')
        // }

        if (isValid) {
            var tempDocflowItem = { ...editedDocflowItem }
            tempDocflowItem.title = itemName
            tempDocflowItem.selectedUsers = selectedUsers
            tempDocflowItem.requiredUsersCount = requiredUsersCount
            tempDocflowItem.itemDeadlineId = itemDeadlineId
            tempDocflowItem.itemDeadlineLabel = itemDeadlineLabel
            tempDocflowItem.requiredFields = [{
                id: 'all',
                label: 'All fields'
            }]
            tempDocflowItem.itemDescription = itemDescription
            tempDocflowItem.notifyAboutOvberdue = notifyAboutOvberdue
            tempDocflowItem.canBeRevoked = canBeRevoked
            tempDocflowItem.canBeSkipped = !canBeSkipped

            this.props.save(tempDocflowItem)
        }
    }

    changeRequiredAcceptancesCount = (type) => {
        const { requiredUsersCount, selectedUsers } = this.state
        if (selectedUsers.length === 0) return

        var newRequiredUsersCount = requiredUsersCount

        if (type === 'PLUS') newRequiredUsersCount++
        if (type === 'MINUS') newRequiredUsersCount--

        if (newRequiredUsersCount < 1) newRequiredUsersCount = 1
        if (newRequiredUsersCount > selectedUsers.length) newRequiredUsersCount = selectedUsers.length

        this.setState({
            requiredUsersCount: newRequiredUsersCount
        })
    }

    render() {
        const { availableDocflowUsers, editedDocflowItem } = this.props
        const {
            itemName, selectedUsers, requiredUsersCount, itemDeadlineId, itemDeadlineLabel, requiredFields, availableFields, canSelectRequiredFields, itemDescription,
            notifyAboutOvberdue, canBeRevoked, canBeSkipped,
            editingItemName, showUsersList, showDeadlinesList, showRequiredFieldsList
        } = this.state

        console.log(editedDocflowItem)

        return (
            <div className="section-settings-window">
                <div className="settings-wrapper docflow" ref={this.settingsWindowWrapperRef}>
                    <h4>
                        {
                            editingItemName ? (
                                <>
                                    <input type="text" name="itemName" value={itemName} onChange={this.onInputChange} autoComplete="off" />
                                    <div className="icon accept-button" onClick={() => this.setState({ editingItemName: false })}></div>
                                </>
                            ) : (
                                <>
                                    <div>{itemName}</div>
                                    <div className="icon edit-button" onClick={() => this.setState({ editingItemName: true })}></div>
                                </>
                            )
                        }
                    </h4>
                    <h6>{this.context.t('Stage settings')}</h6>
                    <div className="settings-section stage-settings">
                        <div className={`form-group ${showUsersList ? 'in-front' : ''}`} ref={this.usersWrapperRef}>
                            <div className="selected-elements" onClick={this.toggleUsersList}>
                                {
                                    selectedUsers.map(user => {
                                        return <div className="element">
                                            {user.name} {user.email ? `(${user.email})` : ''}
                                            <span className="remove" onClick={() => this.removeSelectedUser(user)}></span>
                                        </div>
                                    })
                                }
                            </div>
                            <label htmlFor="docflowDocTypes" onClick={this.toggleUsersList}>{this.context.t('Users/s responsible for the stage')}</label>
                            <span className={`expend-icon ${showUsersList ? 'expended' : ''}`} onClick={this.toggleUsersList}></span>

                            {
                                showUsersList ? (
                                    <ul>
                                        {
                                            ['verification', 'mpk', 'devEx'].includes(editedDocflowItem.itemType) ? (
                                                <li className={`user ${selectedUsers.includes(u => u.id === 0) ? 'selected' : ''}`} onClick={() => this.selectDocflowUser({
                                                    id: 0,
                                                    name: this.context.t('User who added the document')
                                                })}>
                                                    <div className="user-photo">
                                                        <div className="default-pic"></div>
                                                    </div>
                                                    <div className="user-info">
                                                        <div className="user-name">{this.context.t('User who added the document')}</div>
                                                    </div>
                                                </li>
                                            ) : null
                                        }
                                        {
                                            availableDocflowUsers.map(user => {
                                                if (editedDocflowItem.itemType === 'accounting' && !user.isAccountant) return null
                                                return (
                                                    <li className={`user ${selectedUsers.includes(u => u.id === user.id) ? 'selected' : ''}`} onClick={() => this.selectDocflowUser(user)}>
                                                        <div className="user-photo">
                                                            {
                                                                user.profilePic ? (
                                                                    <img src={user.profilePic} alt={user.name} />
                                                                ) : (
                                                                    <div className="default-pic"></div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="user-info">
                                                            <div className="user-name">{user.name}</div>
                                                            {
                                                                user.email ? (
                                                                    <div className="user-email">
                                                                        {user.email}
                                                                    </div>
                                                                ) : null
                                                            }
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : null
                            }
                        </div>

                        {
                            ['verification', 'acceptance'].includes(editedDocflowItem.itemType) ? (
                                <div className="users-count">
                                    <div className="number">
                                        {requiredUsersCount}
                                        <span className="increment" onClick={() => this.changeRequiredAcceptancesCount('PLUS')}></span>
                                        <span className="decrement" onClick={() => this.changeRequiredAcceptancesCount('MINUS')}></span>
                                    </div>
                                    <div className="text">{this.context.t("The number of people who need to accept the document")}</div>
                                </div>
                            ) : null
                        }


                        <div className={`form-group ${showDeadlinesList ? 'in-front' : ''}`} ref={this.deadlineWrapperRef}>
                            <input type="text" name="itemDeadlineLabel" id="itemDeadlineLabel" value={this.context.t(itemDeadlineLabel)} onClick={this.toggleDeadlinesList} autoComplete="off" />
                            <label htmlFor="itemDeadlineLabel" onClick={this.toggleDeadlinesList}>{this.context.t('Stage deadline')}</label>
                            <span className={`expend-icon ${showDeadlinesList ? 'expended' : ''}`} onClick={this.toggleDeadlinesList}></span>

                            {
                                showDeadlinesList ? (
                                    <ul>
                                        {
                                            AVAILABLE_DEDLINES.map(d => {
                                                return (
                                                    <li className={`${itemDeadlineId === d.id ? 'selected' : ''}`} onClick={() => this.selectDeadline(d)}>
                                                        {
                                                            editedDocflowItem.index === 0 ? (
                                                                this.context.t(d.uploadLabel)
                                                            ) : (
                                                                this.context.t(d.label)
                                                            )
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : null
                            }
                        </div>


                        {/* Will be unlocked with OCR page rework */}
                        {
                            editedDocflowItem.itemType === 'verification' && false ? (
                                <div className={`form-group ${showRequiredFieldsList ? 'in-front' : ''}`} ref={this.requiredFieldsWrapperRef}>
                                    <div className="selected-elements">
                                        {
                                            requiredFields.map(field => {
                                                return <div className="element">
                                                    {this.context.t(field.label)}
                                                    {
                                                        canSelectRequiredFields ? (
                                                            <span className="remove" onClick={() => this.removeSelectedRequiredField(field.id)}></span>
                                                        ) : null
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                                    <label htmlFor="docflowDocTypes" onClick={this.toggleRequiredFieldsList}>{this.context.t('Document fields required in this stage')}</label>
                                    {
                                        canSelectRequiredFields ? (
                                            <span className={`expend-icon ${showRequiredFieldsList ? 'expended' : ''}`} onClick={this.toggleRequiredFieldsList}></span>
                                        ) : null
                                    }

                                    {
                                        showRequiredFieldsList ? (
                                            <ul>
                                                {
                                                    availableFields.map(field => {
                                                        return (
                                                            <li className={`${requiredFields.includes(f => f.id === field.id) ? 'selected' : ''}`} onClick={() => this.selectRequiredField(field)}>
                                                                {this.context.t(field.label)}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        ) : null
                                    }
                                </div>
                            ) : null
                        }

                        <div className="form-group">
                            <TextareaAutosize type="text" name="itemDescription" id="itemDescription" value={itemDescription} onChange={this.onInputChange} autoComplete="off" />
                            <label htmlFor="itemDescription">{this.context.t('Stage description')}</label>
                        </div>

                        <div className="checkbox-group first">
                            <div className={`checkbox ${notifyAboutOvberdue ? 'checked' : ''}`} onClick={() => this.setState({ notifyAboutOvberdue: !notifyAboutOvberdue })}></div>
                            <div className="label" onClick={() => this.setState({ notifyAboutOvberdue: !notifyAboutOvberdue })}>{this.context.t("Notify about delay")}</div>
                        </div>

                        {/* {
                            !['verification', 'acceptance', 'mpk', 'devEx'].includes(editedDocflowItem.itemType) ? (
                                <div className="checkbox-group">
                                    <div className={`checkbox ${canBeRevoked ? 'checked' : ''}`} onClick={() => this.setState({ canBeRevoked: !canBeRevoked })}></div>
                                    <div className="label" onClick={() => this.setState({ canBeRevoked: !canBeRevoked })}>{this.context.t("Stage can be rejected")}</div>
                                </div>
                            ) : null
                        } */}

                        {/* {
                            !['verification', 'acceptance', 'mpk', 'devEx'].includes(editedDocflowItem.itemType) ? (
                                <div className="checkbox-group">
                                    <div className={`checkbox ${canBeSkipped ? 'checked' : ''}`} onClick={() => this.setState({ canBeSkipped: !canBeSkipped })}></div>
                                    <div className="label" onClick={() => this.setState({ canBeSkipped: !canBeSkipped })}>{this.context.t("Stage must be completed before the next one can begin")}</div>
                                </div>
                            ) : null
                        } */}

                        <div className="action-buttons align-right">
                            <div className="button reset" onClick={() => this.props.close()}>
                                {this.context.t('Cancel')}
                            </div>
                            <div className="button save" onClick={() => this.saveItem()}>
                                {this.context.t('Save')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

DocflowStageEditWindow.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocflowStageEditWindow)